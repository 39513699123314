import React from 'react';
//import logo from './logo.svg';
import './App.css';
import logo from './island-cars.jpg';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        {/*
          <a
            className="App-link"
            href="https://dodrives.webflow.io"
            rel="noopener noreferrer">
            <img src={logo} alt="Dodrives logo" className="d-block border rounded-5" />
          </a>
        */}
        
        <div className="mt-3">

          <p>
            <a className="text-light" href="https://dodrives.com">Home</a> <span className="tab"></span> 
            <a className="text-light" href="https://wa.me/23058278998?text=Hello%2C%20I%20need%20a%20car">Contact</a>
          </p>

          <a href="https://dodrives.webflow.io">
            <div className="border border-0 rounded-1 m-1" style={{backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center', height:'260px', backgroundImage:`url(${logo})`}}></div>
          </a>
          
          <p className="mt-4 ps-3 pe-3 lh-1">
            <a
              className="App-link"
              href="https://dodrives.webflow.io"
              rel="noopener noreferrer" style={{textDecoration: 'none', color:'white'}}>
              Simple and affordable</a> <a
              className="App-link"
              href="https://wa.me/23058278998?text=Hello%2C%20I%20need%20a%20car"
              rel="noopener noreferrer" style={{color:'white'}}>
              <strong>car rentals</strong></a> <a
              className="App-link"
              href="https://dodrives.webflow.io"
              rel="noopener noreferrer" style={{textDecoration: 'none', color:'white'}}>
                in Mauritius.
              </a>
          </p>
          
        </div>

        <div className="container">

          <div className="mb-5 mt-4">
            <a href="https://wa.me/23058278998?text=Hello%2C%20I%20need%20a%20car" className="btn btn-none text-light btn-lg rounded-5 p-3 ps-5 pe-5 text-bold" style={{backgroundColor:'#ff715b'}}>
              Find a car to rent
            </a>
          </div>

          <div className="fs-4 pt-5 pb-5 ps-3 pe-3 lh-1">

            <div className="pt-5">Are you a driver? Drive and make money whenever you want. <span><a href="https://forms.gle/RF48DfP6Hqsite5MA" className="text-light">
              <span className="text-decoration-underline">Yes I would like to drive</span></a></span></div>

          </div>
          
        </div>
        
      </header>
      
    </div>
  );
}

export default App;
